<template>
    <vx-card :title="'View Inbound Simple Stock Transfer Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>External Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="externalCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedSupplier" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedSrcTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedDestTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedSrcWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="this.selectedDestWarehouse.label" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Document Created At</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.date)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Req. Delivery Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.postingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Received Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(this.receiveDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea :readonly="true" v-model="notes" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Reversal Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date :value="this.reversalDate" @selected="(val) => {
                    this.reversalDate = val
                }" :add-days="1"></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Reversal Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea v-model="reversalNotes" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="60%">SKU</th>
                            <th width="20%">HU</th>
                            <th width="20%">Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom;: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLines[index].selected_sku.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLines[index].selected_hu" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="skuLines[index].qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import Swal from 'sweetalert2'
import Date from "./component/Date.vue";

export default {
    components: {
        Date,
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            externalCode: null,
            selectedSupplier: null,
            selectedSrcTerritory: null,
            selectedDestTerritory: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            date: null,
            postingDate: null,
            reqDeliveryDate: null,
            receiveDate: null,
            notes: null,
            skuLines: [
                {
                    stock_relocation_receive_line_id: 0,
                    selected_sku: null,
                    selected_hu: null,
                    qty: 0,
                }
            ],
            reversalDate: null,
            reversalNotes: null,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/inbound/simple-sto-stock-in/" + this.id)
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.code = resp.data.code
                        this.externalCode = resp.data.external_code
                        this.selectedSupplier = resp.data.selected_supplier.label
                        this.selectedSrcTerritory = resp.data.selected_src_territory.label
                        this.selectedDestTerritory = resp.data.selected_dest_territory.label
                        this.selectedSrcWarehouse = resp.data.selected_src_warehouse.label
                        this.selectedDestWarehouse = resp.data.selected_dest_warehouse
                        this.date = moment(resp.data.date).toDate()
                        this.postingDate = moment(resp.data.posting_date).toDate()
                        this.reqDeliveryDate = moment(resp.data.req_delivery_date).toDate()
                        this.receiveDate = moment(resp.data.receive_date).toDate()
                        this.notes = resp.data.notes
                        this.skuLines = resp.data.sku_lines
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleSubmit() {
            if (!this.reversalDate) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Date of reversal is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.reversalNotes) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Reason of reversal is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let message = ""
            let skus = []
            this.skuLines.forEach((val) => {
                skus.push({
                    stock_relocation_receive_line_id: val.stock_relocation_receive_line_id,
                    item_id: val.selected_sku.id,
                    item_unit: val.selected_hu,
                    quantity: parseInt(val.qty),
                })
            })

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("stock_in_id", this.id)
            formData.append("to_warehouse_id", this.selectedDestWarehouse.id)
            formData.append("reversal_date", this.reversalDate
                ? moment(this.reversalDate).format("YYYY-MM-DD")
                : null)
            formData.append("reversal_notes", this.reversalNotes)
            formData.append("skus", JSON.stringify(skus))

            this.$vs.loading();
            this.$http.post("/api/wms/v1/inbound/simple-sto-stock-in/check-stock", formData)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'All stock available!',
                            text: "Are you sure want to reversal this data?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'REVERSAL'
                        }).then((result) => {
                            if(result.isConfirmed) {
                                this.$vs.loading();
                                this.$http
                                    .post("/api/wms/v1/inbound/simple-sto-stock-in/reversal", formData)
                                    .then(resp => {
                                        if (resp.code == 200) {
                                            this.$vs.loading.close();
                                            Swal.fire(
                                                'Done!',
                                                'Document has been reversed.',
                                                'success'
                                            )
                                            this.draw++;
                                            this.handleBack()
                                        } else {
                                            this.$vs.loading.close();
                                            Swal.fire({
                                                title: 'Something gone wrong!',
                                                text: resp.message,
                                                icon: 'info',
                                            })
                                        }
                                    });
                            }
                        })
                    } else {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'Stock is less than required!',
                            text: resp.message,
                            icon: 'error',
                        })
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleBack() {
            this.$router.push({
                name: "inbound.simple-sto-stock-in",
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
    },
}
</script>